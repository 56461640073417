import i18n from "@/i18n";
import axios from "axios";
import Model from "./model";

import CryptoJS from "crypto-js";

const getDefaultState = () => {
  return {
    loggedIn: false,
    accessToken: "",
    tokenType: "",
    expiresAt: "",
    userId: -1,
    userName: "",
    userSurnames: "",
    organisationId: -1,
    organisationIdToken: "",
    organisationName: "",
    organisationAppearance: {},
    level: -1,
    role: -1,
    impersonationsFrom: [],
    impersonationsTo: [],
    languageSetBy: null,
    userLanguage: "",
    organisationLanguage: "",
    forcedLanguage: "",
  };
};

// initial state
const state = getDefaultState();

// getters
const getters = {
  getLoggedIn(state) {
    return state.loggedIn;
  },
  getUserId(state) {
    return state.userId;
  },
  getUserName(state) {
    return state.userName;
  },
  getUserSurnames(state) {
    return state.userSurnames;
  },
  getOrganisationId(state) {
    return state.organisationId;
  },
  getOrganisationIdToken(state) {
    return state.organisationIdToken;
  },
  calcOrganisationIdToken(state) {
    let organisationIdToken = null;
    if (state.organisationId) {
      organisationIdToken = CryptoJS.AES.encrypt(
        `${state.organisationId}`,
        process.env.VUE_APP_LOGIN_URL_SALT
      );
      organisationIdToken = organisationIdToken.toString();
    }
    return organisationIdToken;
  },
  getOrganisationName(state) {
    return state.organisationName;
  },
  getOrganisationAppearance(state) {
    return state.organisationAppearance;
  },
  getOrganisationLevel(state) {
    return state.level;
  },
  getUserRole(state) {
    return state.role;
  },
  getImpersonationsTo(state) {
    return state.impersonationsTo;
  },
  getImpersonationsFrom(state) {
    return state.impersonationsFrom;
  },
  getImpersonationsFromById(state) {
    return (id) => {
      if (id === undefined) {
        return [];
      } else {
        let impersonations = state.impersonationsFrom.filter((item) => {
          return item.to_organisation_id === id;
        });

        return impersonations;
      }
    };
  },
  hasImpersonationsFromOrganisation(state) {
    return (id) => {
      if (id === undefined) {
        return false;
      } else {
        let impersonation = state.impersonationsFrom.find((item) => {
          return item.to_organisation_id === id;
        });

        if (impersonation) {
          return true;
        }
        return false;
      }
    };
  },
};

// actions
const actions = {
  resetState({ commit }) {
    commit("resetState");
  },
  register({ rootGetters }, payload) {
    let forStorage = new Model(payload);
    forStorage = forStorage.filterForStorage();

    let authMethod = rootGetters["config/getAuthMethod"];
    let authUrlRegister = rootGetters["config/getAuthUrlRegister"];

    if (authMethod === "internal") {
      return axios({
        method: "POST",
        url: authUrlRegister,
        headers: { "content-type": "application/json" },
        data: forStorage,
      })
        .then((/* result */) => {
          // console.log('register', result)
          return true;
        })
        .catch((error) => {
          // console.error('ERROR register', error)
          throw error;
        });
    } else {
      return new Promise((resolve, reject) => {
        reject("Not available for this auth method.");
      });
    }
  },

  login({ commit, rootGetters }, payload) {
    let forStorage = new Model(payload);
    forStorage = forStorage.filterForLogin();

    let authMethod = rootGetters["config/getAuthMethod"];
    let authUrlCsrfCookie = rootGetters["config/getAuthUrlCsrfCookie"];
    let authUrlLogin = rootGetters["config/getAuthUrlLogin"];
    let authUrlLoggedInUserInfo =
      rootGetters["config/getAuthUrlLoggedInUserInfo"];

    if (authMethod === "internal") {
      return axios({
        method: "GET",
        url: authUrlCsrfCookie,
        headers: { "content-type": "application/json" },
      })
        .then(() => {
          return axios({
            method: "POST",
            url: authUrlLogin,
            headers: { "content-type": "application/json" },
            data: forStorage,
          });
        })
        .then(() => {
          return axios({
            method: "GET",
            url: authUrlLoggedInUserInfo,
            headers: { "content-type": "application/json" },
          });
        })
        .then((result) => {
          commit("loginOnSuccess", result.data);
          return true;
        });
    } else if (authMethod === "CAS3") {
      // Nothing to do, this works by redirecting the entire web.
    }
  },

  impersonateTo({ commit, dispatch, rootGetters }, payload) {
    let forStorage = {
      to_user_id: -1,
      to_organisation_id: -1,
    };
    if (payload.to_user_id) {
      forStorage.to_user_id = payload.to_user_id;
    }
    if (payload.to_organisation_id) {
      forStorage.to_organisation_id = payload.to_organisation_id;
    }

    let authMethod = rootGetters["config/getAuthMethod"];
    let authUrlCsrfCookie = rootGetters["config/getAuthUrlCsrfCookie"];
    let authUrlLoggedInUserInfo =
      rootGetters["config/getAuthUrlLoggedInUserInfo"];
    let apiUrlWeb = rootGetters["config/getApiUrlWeb"];

    if (authMethod === "internal") {
      return axios({
        method: "GET",
        url: authUrlCsrfCookie,
        headers: { "content-type": "application/json" },
      })
        .then(() => {
          return axios({
            method: "POST",
            url: `${apiUrlWeb}/impersonate-to`,
            headers: { "content-type": "application/json" },
            data: forStorage,
          });
        })
        .then(() => {
          return axios({
            method: "GET",
            url: authUrlLoggedInUserInfo,
            headers: { "content-type": "application/json" },
          });
        })
        .then((result) => {
          // console.log("login", result);
          return dispatch("resetState").then((/* result */) => {
            commit("loginOnSuccess", result.data);
          });
        })
        .catch((error) => {
          // console.error('ERROR login', error)
          throw error;
        });
    } else {
      return new Promise((resolve, reject) => {
        reject("Not available for this auth method.");
      });
    }
  },

  logout({ commit, dispatch, rootGetters }) {
    let authMethod = rootGetters["config/getAuthMethod"];
    let apiUrlWeb = rootGetters["config/getApiUrlWeb"];
    let apiUrl = rootGetters["config/getApiUrl"];
    let apiUrlAuth = rootGetters["config/getApiUrlAuth"];

    if (authMethod === "internal") {
      return axios({
        method: "POST",
        url: `${apiUrlWeb}/leave-impersonation`,
        headers: { "content-type": "application/json" },
      })
        .then((result) => {
          // console.log("logout 1");
          if (result.data && result.data.user_id) {
            // console.log("logout 1.1");
            return axios({
              method: "GET",
              url: `${apiUrl}/user`,
              headers: { "content-type": "application/json" },
            }).then((result) => {
              // console.log("logout 1.1.1");
              // console.log("logout", result.data);
              return dispatch("resetState").then((/* result */) => {
                if (result.data && result.data.user_id) {
                  commit("loginOnSuccess", result.data);
                } else {
                  commit("logoutOnSuccess", result.data);
                  commit("resetLanguage");
                }
                location.reload();
                return true;
              });
            });
          } else {
            // console.log("logout 1.2");
            return axios({
              method: "POST",
              url: `${apiUrlAuth}/logout`,
              headers: { "content-type": "application/json" },
            }).then((result) => {
              // console.log("logout 1.2.1");
              // console.log('logout', result)
              commit("logoutOnSuccess", result.data);
              commit("resetLanguage");
              return true;
            });
          }
        })
        .catch(() => {
          // console.log("logout 2");
          return axios({
            method: "POST",
            url: `${apiUrlAuth}/logout`,
            headers: { "content-type": "application/json" },
          })
            .then((result) => {
              // console.log("logout 2.1");
              // console.log('logout', result)
              commit("logoutOnSuccess", result.data);
              commit("resetLanguage");
              return true;
            })
            .catch((error) => {
              // console.log("logout 2.2");
              throw error;
            });
        });
    }
  },

  validate({ commit, rootGetters }) {
    let authMethod = rootGetters["config/getAuthMethod"];
    let authUrlCsrfCookie = rootGetters["config/getAuthUrlCsrfCookie"];
    let authUrlValidate = rootGetters["config/getAuthUrlValidate"];
    let authUrlLoggedInUserInfo =
      rootGetters["config/getAuthUrlLoggedInUserInfo"];

    if (authMethod === "internal") {
      return axios({
        method: "GET",
        url: authUrlCsrfCookie,
        headers: { "content-type": "application/json" },
      })
        .then(() => {
          return axios({
            method: "GET",
            url: authUrlLoggedInUserInfo,
            headers: { "content-type": "application/json" },
          });
        })
        .then((result) => {
          // console.log('login', result)
          commit("validateOnSuccess", result.data);
          if (result.data.language_iso639_1) {
            commit("setUserLanguage", result.data.language_iso639_1);
          }
          if (result.data.organisation_language_iso639_1) {
            commit(
              "setOrganisationLanguage",
              result.data.organisation_language_iso639_1
            );
          }
          return true;
        })
        .catch((error) => {
          // console.error('ERROR login', error)
          throw error;
        });
    } else if (authMethod === "CAS3") {
      let token00 = rootGetters["config/getToken00"];
      if (token00) {
        return axios({
          method: "GET",
          url: `${authUrlValidate}&ticket=${token00}`,
        })
          .then((result) => {
            console.log("Validate CAS success: ", result);
          })
          .then(() => {
            return axios({
              method: "GET",
              url: authUrlLoggedInUserInfo,
              headers: { "content-type": "application/json" },
            });
          })
          .then((result) => {
            // console.log('login', result)
            commit("validateOnSuccess", result.data);
            if (result.data.language_iso639_1) {
              commit("setUserLanguage", result.data.language_iso639_1);
            }
            if (result.data.organisation_language_iso639_1) {
              commit(
                "setOrganisationLanguage",
                result.data.organisation_language_iso639_1
              );
            }
            return true;
          })
          .catch((error) => {
            let errorMessage = "Validate CAS error";
            let serverMessage = error.response.data.message;
            if (serverMessage) {
              errorMessage = serverMessage;
            }
            console.log("Validate CAS error: ", error.response.data);

            commit(
              "status/showError",
              i18n.t("auth.errors.cas['" + errorMessage + "']"),
              { root: true }
            );
            throw error;
          });
      } else {
        return new Promise((resolve, reject) => {
          reject("No token available to validate session.");
        });
      }
    }
  },

  forgotPassword({ rootGetters }, payload) {
    let authMethod = rootGetters["config/getAuthMethod"];
    let authUrlCsrfCookie = rootGetters["config/getAuthUrlCsrfCookie"];
    let authUrlForgotPassword = rootGetters["config/getAuthUrlForgotPassword"];

    if (authMethod === "internal") {
      return axios({
        method: "GET",
        url: authUrlCsrfCookie,
        headers: { "content-type": "application/json" },
      }).then(() => {
        return axios({
          method: "POST",
          url: authUrlForgotPassword,
          headers: { "content-type": "application/json" },
          data: {
            email: payload.email,
          },
        });
      });
    } else {
      return new Promise((resolve, reject) => {
        reject("Not available for this auth method.");
      });
    }
  },

  resetPassword({ rootGetters }, payload) {
    let authMethod = rootGetters["config/getAuthMethod"];
    let authUrlCsrfCookie = rootGetters["config/getAuthUrlCsrfCookie"];
    let authUrlResetPassword = rootGetters["config/getAuthUrlResetPassword"];

    if (authMethod === "internal") {
      return axios({
        method: "GET",
        url: authUrlCsrfCookie,
        headers: { "content-type": "application/json" },
      }).then(() => {
        return axios({
          method: "POST",
          url: authUrlResetPassword,
          headers: { "content-type": "application/json" },
          data: {
            token: payload.token,
            email: payload.email,
            password: payload.password,
            password_confirmation: payload.password_confirmation,
          },
        });
      });
    } else {
      return new Promise((resolve, reject) => {
        reject("Not available for this auth method.");
      });
    }
  },
};

// mutations
const mutations = {
  setNewOrganisationAppearance(state, newValue) {
    state.organisationAppearance = Object.assign({}, newValue);
  },
  setNewOrganisationName(state, newValue) {
    state.organisationName = newValue;
  },
  setNewOrganisationId(state, newValue) {
    state.organisationId = newValue;
  },
  setOrganisationIdToken(state, newValue) {
    state.organisationIdToken = newValue;
  },
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  },
  loginOnSuccess(state, item) {
    state.accessToken = item.access_token;
    state.tokenType = item.token_type;
    state.expiresAt = item.expires_at;
    state.userId = item.user_id;
    state.userName = item.user_name;
    state.userSurnames = item.user_surnames;
    state.organisationId = item.organisation_id;
    state.organisationName = item.organisation_name;
    try {
      state.organisationAppearance = JSON.parse(item.organisation_appearance);
    } catch {
      state.organisationAppearance = {};
    }
    state.level = item.level;
    state.role = item.role;
    if (state.accessToken) {
      state.loggedIn = true;
      localStorage.setItem("accessToken", item.access_token);
    }
  },
  logoutOnSuccess(state /* , item */) {
    Object.assign(state, getDefaultState());
    localStorage.setItem("accessToken", "");
  },
  validateOnSuccess(state, item) {
    state.userId = item.user_id;
    state.organisationId = item.organisation_id;
    state.organisationName = item.organisation_name;
    try {
      state.organisationAppearance = JSON.parse(item.organisation_appearance);
    } catch {
      state.organisationAppearance = {};
    }
    state.level = item.level;
    state.role = item.role;
    state.loggedIn = true;
    if (item.impersonationsFrom) {
      state.impersonationsFrom = item.impersonationsFrom;
    }
    if (item.impersonationsTo) {
      state.impersonationsTo = item.impersonationsTo;
    }
  },
  setOrganisationLanguage(state, newValue) {
    state.organisationLanguage = newValue;
    if (state.languageSetBy === null) {
      state.languageSetBy = "organisation";
      i18n.locale = state.organisationLanguage;
    }
  },
  setUserLanguage(state, newValue) {
    state.userLanguage = newValue;
    state.languageSetBy = "user";
    i18n.locale = state.userLanguage;
  },
  setForcedLanguage(state, newValue) {
    state.forcedLanguage = newValue;
    state.languageSetBy = "forced";
    i18n.locale = state.forcedLanguage;
  },
  resetLanguage(state) {
    if (state.languageSetBy === "forced") {
      state.languageSetBy = null;
      i18n.locale = process.env.VUE_APP_I18N_LOCALE;
    } else if (state.languageSetBy === "organisation") {
      state.languageSetBy = null;
      i18n.locale = process.env.VUE_APP_I18N_LOCALE;
    } else if (state.languageSetBy === "user") {
      if (state.organisationLanguage) {
        state.languageSetBy = "organisation";
        i18n.locale = process.env.VUE_APP_I18N_LOCALE;
      } else {
        state.languageSetBy = null;
        i18n.locale = process.env.VUE_APP_I18N_LOCALE;
      }
    } else {
      state.languageSetBy = null;
      i18n.locale = process.env.VUE_APP_I18N_LOCALE;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
